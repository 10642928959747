var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CCardBody",
    [
      _c("alert-section", {
        attrs: {
          successAlertMessage: _vm.successAlertMessage,
          dismissSecs: _vm.dismissSecs,
          dismissSuccessAlert: _vm.dismissSuccessAlert,
          errorAlertMessage: _vm.errorAlertMessage,
          showErrorAlert: _vm.showErrorAlert,
        },
      }),
      _c(
        "CCard",
        { staticClass: "filters", attrs: { "accent-color": "warning" } },
        [
          _c(
            "CCardHeader",
            {
              staticClass: "text-warning shadow-none card-header",
              attrs: { role: "button" },
              on: { click: _vm.resetFilters },
            },
            [
              _c(
                "strong",
                { staticClass: "m-0" },
                [
                  _c("CIcon", {
                    attrs: {
                      name: `cil-filter${
                        _vm.$store.state.filterShow ? "-x" : ""
                      }`,
                    },
                  }),
                  _vm._v(" Filters"),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "card-header-actions" },
                [
                  _c("CIcon", {
                    attrs: {
                      name: `cil-chevron-${
                        _vm.$store.state.filterShow ? "bottom" : "top"
                      }`,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "CCollapse",
            { attrs: { show: _vm.$store.state.filterShow } },
            [
              _c("CCardBody", { staticClass: "p-2" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-lg col-filter mb-2 mb-lg-0" },
                    [
                      _c("v-select", {
                        staticClass: "v-select-filter",
                        attrs: {
                          placeholder: "Filter devices by...",
                          options: [
                            { value: "linked", label: "Linked" },
                            { value: "unlinked", label: "Not Linked" },
                            { value: "software", label: "Software" },
                            { value: "hardware", label: "Hardware" },
                          ],
                          reduce: (o) => o.value,
                          multiple: "",
                          searchable: false,
                        },
                        on: { input: _vm.filterDevices },
                        model: {
                          value: _vm.filterBy,
                          callback: function ($$v) {
                            _vm.filterBy = $$v
                          },
                          expression: "filterBy",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-lg col-filter mb-2 mb-lg-0" },
                    [
                      _c("v-select", {
                        staticClass: "v-select-filter",
                        attrs: {
                          placeholder: "Select restaurant..",
                          options: _vm.allRestaurants,
                          disabled: _vm.restaurantId,
                        },
                        on: { input: _vm.inputFilter },
                        model: {
                          value: _vm.selectedRestaurant,
                          callback: function ($$v) {
                            _vm.selectedRestaurant = $$v
                          },
                          expression: "selectedRestaurant",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-lg col-filter mb-2 mb-lg-0" },
                    [
                      _c("CInput", {
                        staticClass: "mb-0",
                        attrs: { type: "search", placeholder: "Search.." },
                        on: { input: _vm.searchFilter },
                        model: {
                          value: _vm.search,
                          callback: function ($$v) {
                            _vm.search = $$v
                          },
                          expression: "search",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("CDataTable", {
        attrs: {
          striped: "",
          hover: "",
          items: _vm.loadedItems,
          fields: _vm.fields,
          sorter: { external: true, resetable: true },
          "column-filter": { external: true, lazy: true },
          itemsPerPageSelect: {
            external: true,
            values: [5, 15, 25, 50, 100, 250, 500],
          },
          "items-per-page": _vm.itemsPerPage,
          loading: _vm.loading,
          noItemsView: {
            noResults: "No filtering results are available!",
            noItems: "No devices found!",
          },
        },
        on: {
          "update:itemsPerPage": function ($event) {
            _vm.itemsPerPage = $event
          },
          "update:items-per-page": function ($event) {
            _vm.itemsPerPage = $event
          },
          "update:sorter-value": _vm.sorterValue,
          "pagination-change": _vm.paginationChange,
        },
        scopedSlots: _vm._u([
          {
            key: "restaurant",
            fn: function ({ item }) {
              return [
                item.restaurants.length > 0
                  ? _c(
                      "td",
                      [
                        _c(
                          "CLink",
                          {
                            attrs: {
                              to: {
                                name: "View Restaurant",
                                params: { id: item.restaurants[0].id },
                              },
                              target: "_blank",
                            },
                          },
                          [
                            _c("strong", [
                              _vm._v("#" + _vm._s(item.restaurants[0].id)),
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  item.restaurants[0].restaurant_postcode
                                ) +
                                " " +
                                _vm._s(item.restaurants[0].restaurant_city) +
                                ", "
                            ),
                            _c("strong", [
                              _vm._v(
                                _vm._s(item.restaurants[0].restaurant_name)
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    )
                  : _c("td", { staticClass: "text-muted" }, [
                      _vm._v(" Not Linked! "),
                    ]),
              ]
            },
          },
          {
            key: "software",
            fn: function ({ item }) {
              return [
                _c("td", { staticClass: "text-muted" }, [
                  _vm._v(
                    " " +
                      _vm._s(item.software == true ? "Software" : "Hardware") +
                      " "
                  ),
                ]),
              ]
            },
          },
          {
            key: "ws_status",
            fn: function ({ item }) {
              return [
                _c("td", { staticClass: "py-2 text-center" }, [
                  item.ws_status == 1
                    ? _c("div", {
                        staticClass: "circle bg-success",
                        attrs: { name: "cis-circle" },
                      })
                    : _c("div", {
                        staticClass: "circle bg-danger",
                        attrs: { name: "cis-circle" },
                      }),
                ]),
              ]
            },
          },
          {
            key: "show_history",
            fn: function ({ item, index }) {
              return [
                _c(
                  "td",
                  { staticClass: "py-2 text-center" },
                  [
                    _c(
                      "CButton",
                      {
                        attrs: {
                          color: "primary",
                          variant: "outline",
                          square: "",
                          size: "sm",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.toggleHistory(item, index)
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(Boolean(item._toggled) ? "Hide" : "Show") +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "details",
            fn: function ({ item }) {
              return [
                _c(
                  "CCollapse",
                  {
                    attrs: {
                      show: Boolean(item._toggled),
                      duration: _vm.collapseDuration,
                    },
                  },
                  [
                    _c(
                      "CCardBody",
                      { staticStyle: { "background-color": "#fff" } },
                      [
                        item.history.length
                          ? _c("div", { staticClass: "table-responsive" }, [
                              _c(
                                "table",
                                { staticClass: "table table-sm table-history" },
                                [
                                  _c("thead", [
                                    _c("tr", [
                                      _c(
                                        "th",
                                        { staticStyle: { width: "5%" } },
                                        [_vm._v("Status")]
                                      ),
                                      _c(
                                        "th",
                                        { staticStyle: { width: "15%" } },
                                        [_vm._v("Updated At")]
                                      ),
                                      _c(
                                        "th",
                                        { staticStyle: { width: "25%" } },
                                        [_vm._v("Linked Restaurant")]
                                      ),
                                      _c(
                                        "th",
                                        { staticStyle: { width: "15%" } },
                                        [_vm._v("By")]
                                      ),
                                      _c(
                                        "th",
                                        { staticStyle: { width: "40%" } },
                                        [_vm._v("Note")]
                                      ),
                                    ]),
                                  ]),
                                  _vm._l(item.history, function (h) {
                                    return _c(
                                      "tr",
                                      {
                                        key: h.id,
                                        class: h.remove
                                          ? "text-danger"
                                          : "text-success",
                                      },
                                      [
                                        _c(
                                          "th",
                                          { attrs: { scope: "row" } },
                                          [
                                            _c("CIcon", {
                                              attrs: {
                                                name: `cil-${
                                                  h.remove ? "x" : "check-alt"
                                                }`,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c("td", [
                                          _vm._v(_vm._s(h.updated_at)),
                                        ]),
                                        _c(
                                          "td",
                                          [
                                            h.restaurant
                                              ? _c(
                                                  "CLink",
                                                  {
                                                    attrs: {
                                                      to: {
                                                        name: "View Restaurant",
                                                        params: {
                                                          id: h.restaurant.id,
                                                        },
                                                      },
                                                      target: "_blank",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " #" +
                                                        _vm._s(
                                                          h.restaurant.id
                                                        ) +
                                                        " " +
                                                        _vm._s(
                                                          h.restaurant
                                                            .restaurant_postcode
                                                        ) +
                                                        " " +
                                                        _vm._s(
                                                          h.restaurant
                                                            .restaurant_city
                                                        ) +
                                                        ", " +
                                                        _vm._s(
                                                          h.restaurant
                                                            .restaurant_name
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          [
                                            h.user
                                              ? _c(
                                                  "CLink",
                                                  {
                                                    attrs: {
                                                      to: {
                                                        name: "User",
                                                        params: {
                                                          id: h.user.id,
                                                        },
                                                      },
                                                      target: "_blank",
                                                    },
                                                  },
                                                  [
                                                    _c("strong", [
                                                      _vm._v(
                                                        _vm._s(
                                                          h.user.first_name
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _c("td", [_vm._v(_vm._s(h.note))]),
                                      ]
                                    )
                                  }),
                                ],
                                2
                              ),
                            ])
                          : _c("span", { staticClass: "text-danger" }, [
                              _vm._v("⚠ No device history found!"),
                            ]),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "actions",
            fn: function ({ item, index }) {
              return [
                _c(
                  "td",
                  { staticClass: "py-2 text-center" },
                  [
                    _c(
                      "CButton",
                      {
                        attrs: {
                          to: { name: "Edit Device", params: { id: item.id } },
                          size: "sm",
                          color: "info",
                          variant: "ghost",
                          shape: "pill",
                        },
                      },
                      [_c("CIcon", { attrs: { name: "cil-align-left" } })],
                      1
                    ),
                    _c(
                      "CButton",
                      {
                        staticClass: "ml-1",
                        attrs: {
                          size: "sm",
                          color: "danger",
                          variant: "ghost",
                          shape: "pill",
                          disabled: item.restaurants.length > 0,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.deleteItem(item, index)
                          },
                        },
                      },
                      [_c("CIcon", { attrs: { name: "cil-trash" } })],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "CCard",
        { staticClass: "actions sticky-bottom" },
        [
          _c("CCardBody", { staticClass: "p-2" }, [
            _c(
              "div",
              {
                staticClass: "d-flex flex-wrap align-items-center",
                staticStyle: { gap: "0.75rem" },
              },
              [
                _c("div", [
                  _c("h5", { staticClass: "mt-1" }, [
                    _vm._v("Total: "),
                    _c(
                      "span",
                      { staticClass: "d-inline count bg-primary pb-1" },
                      [_vm._v(_vm._s(_vm.total))]
                    ),
                  ]),
                ]),
                _vm.pages > 1
                  ? _c(
                      "div",
                      { staticClass: "ml-auto" },
                      [
                        _c("CPagination", {
                          attrs: {
                            align: "center",
                            dots: false,
                            pages: _vm.pages,
                            "active-page": _vm.activePage,
                          },
                          on: {
                            "update:activePage": function ($event) {
                              _vm.activePage = $event
                            },
                            "update:active-page": function ($event) {
                              _vm.activePage = $event
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }