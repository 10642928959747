var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CRow",
    [
      _c(
        "CCol",
        { attrs: { sm: "12" } },
        [
          _c(
            "CCard",
            [
              _c(
                "CCardHeader",
                [
                  _c("CIcon", { attrs: { name: "cil-mobile" } }),
                  _c("h5", { staticClass: "d-inline ml-2" }, [
                    _vm._v("Devices"),
                  ]),
                  _c("div", { staticClass: "card-header-actions" }),
                ],
                1
              ),
              _c("BackendTable"),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }